// @ts-nocheck
export default {
  'balance-scale': {
    path: [
      'M256 336h-.02c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0C-2.06 328.75.02 320.33.02 336H0c0 44.18 57.31 80 128 80s128-35.82 128-80zM128 176l72 144H56l72-144zm511.98 160c0-16.18 1.34-8.73-85.05-181.51-17.65-35.29-68.19-35.36-85.87 0-87.12 174.26-85.04 165.84-85.04 181.51H384c0 44.18 57.31 80 128 80s128-35.82 128-80h-.02zM440 320l72-144 72 144H440zm88 128H352V153.25c23.51-10.29 41.16-31.48 46.39-57.25H528c8.84 0 16-7.16 16-16V48c0-8.84-7.16-16-16-16H383.64C369.04 12.68 346.09 0 320 0s-49.04 12.68-63.64 32H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h129.61c5.23 25.76 22.87 46.96 46.39 57.25V448H112c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h416c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z',
    ],
    viewBox: '0 0 640 512',
  },
  university: {
    path: [
      'M472 440h-8v-56c0-13.255-10.745-24-24-24h-16V208h-48v152h-48V208h-48v152h-48V208h-48v152h-48V208H88v152H72c-13.255 0-24 10.745-24 24v56h-8c-13.255 0-24 10.745-24 24v16a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-16c0-13.255-10.745-24-24-24zm-56 0H96v-32h320v32zm72.267-322.942L271.179 26.463a48.004 48.004 0 0 0-30.358 0L23.733 117.058A11.999 11.999 0 0 0 16 128.274V156c0 6.627 5.373 12 12 12h20v12c0 6.627 5.373 12 12 12h392c6.627 0 12-5.373 12-12v-12h20c6.627 0 12-5.373 12-12v-27.726c0-4.982-3.077-9.445-7.733-11.216zM64 144l192-72 192 72H64z',
    ],
    viewBox: '0 0 512 512',
  },
  'shopping-cart': {
    path: [
      'M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z',
    ],
    viewBox: '0 0 576 512',
  },
  analytics: {
    path: [
      'M510.62 92.63C516.03 94.74 521.85 96 528 96c26.51 0 48-21.49 48-48S554.51 0 528 0s-48 21.49-48 48c0 2.43.37 4.76.71 7.09l-95.34 76.27c-5.4-2.11-11.23-3.37-17.38-3.37s-11.97 1.26-17.38 3.37L255.29 55.1c.35-2.33.71-4.67.71-7.1 0-26.51-21.49-48-48-48s-48 21.49-48 48c0 4.27.74 8.34 1.78 12.28l-101.5 101.5C56.34 160.74 52.27 160 48 160c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-4.27-.74-8.34-1.78-12.28l101.5-101.5C199.66 95.26 203.73 96 208 96c6.15 0 11.97-1.26 17.38-3.37l95.34 76.27c-.35 2.33-.71 4.67-.71 7.1 0 26.51 21.49 48 48 48s48-21.49 48-48c0-2.43-.37-4.76-.71-7.09l95.32-76.28zM400 320h-64c-8.84 0-16 7.16-16 16v160c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V336c0-8.84-7.16-16-16-16zm160-128h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm-320 0h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zM80 352H16c-8.84 0-16 7.16-16 16v128c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V368c0-8.84-7.16-16-16-16z',
    ],
    viewBox: '0 0 576 512',
  },
  plus: {
    path: [
      'M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z',
    ],
    viewBox: '0 0 384 512',
  },
  'arrow-up': {
    path: [
      'M6.101 261.899L25.9 281.698c4.686 4.686 12.284 4.686 16.971 0L198 126.568V468c0 6.627 5.373 12 12 12h28c6.627 0 12-5.373 12-12V126.568l155.13 155.13c4.686 4.686 12.284 4.686 16.971 0l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L232.485 35.515c-4.686-4.686-12.284-4.686-16.971 0L6.101 244.929c-4.687 4.686-4.687 12.284 0 16.97z',
    ],
    viewBox: '0 0 448 512',
  },
  'arrow-down': {
    path: [
      'M441.9 250.1l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L250 385.4V44c0-6.6-5.4-12-12-12h-28c-6.6 0-12 5.4-12 12v341.4L42.9 230.3c-4.7-4.7-12.3-4.7-17 0L6.1 250.1c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z',
    ],
    viewBox: '0 0 448 512',
  },
  'arrow-back-24': {
    path: ['M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'],
    viewBox: '0 0 24, 24',
  },
  'arrow-forward-24': {
    path: ['M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z'],
    viewBox: '0 0 24 24',
  },
  'ellipsis-h': {
    path: [
      'M304 256c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zm120-48c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm-336 0c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48z',
    ],
    viewBox: '0 0 512 512',
  },
  mines: {
    path: [
      'M140 284c-6.6 0-12-5.4-12-12v-32c0-6.6 5.4-12 12-12h232c6.6 0 12 5.4 12 12v32c0 6.6-5.4 12-12 12H140zm364-28c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-48 0c0-110.5-89.5-200-200-200S56 145.5 56 256s89.5 200 200 200 200-89.5 200-200z',
    ],
    viewBox: '0 0 512 512',
  },
  'arrow-circle-left': {
    path: [
      'M504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zM256 472c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm-12.5-92.5l-115.1-115c-4.7-4.7-4.7-12.3 0-17l115.1-115c4.7-4.7 12.3-4.7 17 0l6.9 6.9c4.7 4.7 4.7 12.5-.2 17.1L181.7 239H372c6.6 0 12 5.4 12 12v10c0 6.6-5.4 12-12 12H181.7l85.6 82.5c4.8 4.7 4.9 12.4.2 17.1l-6.9 6.9c-4.8 4.7-12.4 4.7-17.1 0z',
    ],
    viewBox: '0 0 512 512',
  },
  'arrow-circle-right': {
    path: [
      'M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zM256 40c118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216zm12.5 92.5l115.1 115c4.7 4.7 4.7 12.3 0 17l-115.1 115c-4.7 4.7-12.3 4.7-17 0l-6.9-6.9c-4.7-4.7-4.7-12.5.2-17.1l85.6-82.5H140c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12h190.3l-85.6-82.5c-4.8-4.7-4.9-12.4-.2-17.1l6.9-6.9c4.8-4.7 12.4-4.7 17.1 0z',
    ],
    viewBox: '0 0 512 512',
  },
  times: {
    path: [
      'M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z',
    ],
    viewBox: '0 0 320 512',
  },

  receipt: {
    path: [
      'M344 288H104c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8zM400.8 5.7L357.3 37 318.7 9.2c-16.8-12.1-39.2-12.1-56.1 0L224 37 185.4 9.2a47.888 47.888 0 0 0-56.1 0L90.7 37 47.2 5.7C27.4-8.5 0 5.6 0 29.9v452.3c0 23.8 27.1 38.6 47.2 24.2L90.7 475l38.6 27.8c16.8 12.1 39.2 12.1 56.1 0L224 475l38.6 27.8c16.8 12.1 39.3 12.1 56.1 0l38.6-27.8 43.5 31.3c19.8 14.2 47.2.1 47.2-24.1V29.9C448 6 420.9-8.7 400.8 5.7zm-.8 440.8l-42.7-30.7-66.7 48-66.7-48-66.7 48-66.7-48L48 446.5v-381l42.7 30.7 66.7-48 66.7 48 66.7-48 66.7 48L400 65.5v381zM344 176H104c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h240c4.4 0 8-3.6 8-8v-32c0-4.4-3.6-8-8-8zz',
    ],
    viewBox: '0 0 448 512',
  },

  trash: {
    path: [
      'M432 80h-82.4l-34-56.7A48 48 0 0 0 274.4 0H173.6a48 48 0 0 0-41.2 23.3L98.4 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16l21.2 339a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM173.6 48h100.8l19.2 32H154.4zm173.3 416H101.11l-21-336h287.8z',
    ],
    viewBox: '0 0 448 512',
  },
  archive: {
    path: [
      'M464 32H48C21.5 32 0 53.5 0 80v80c0 8.8 7.2 16 16 16h16v272c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V176h16c8.8 0 16-7.2 16-16V80c0-26.5-21.5-48-48-48zm-32 400H80V176h352v256zm32-304H48V80h416v48zM204 272h104c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H204c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12',
    ],
    viewBox: '0 0 512 512',
  },
  pen: {
    path: [
      'M493.26 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.25 18.74l-74.49 74.49L256 127.98 12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.79-.05 2.69-.15l114.14-12.61L384.02 256l34.74-34.74 74.49-74.49c25-25 25-65.52.01-90.51zM118.75 453.39l-67.58 7.46 7.53-67.69 231.24-231.24 31.02-31.02 60.14 60.14-31.02 31.02-231.33 231.33zm340.56-340.57l-44.28 44.28-60.13-60.14 44.28-44.28c4.08-4.08 8.84-4.69 11.31-4.69s7.24.61 11.31 4.69l37.51 37.51c6.24 6.25 6.24 16.4 0 22.63z',
    ],
    viewBox: '0 0 512 512',
  },
  'sort-up': {
    path: [
      'M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z',
    ],
    viewBox: '0 0 320 512',
  },
  'sort-down': {
    path: [
      'M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z',
    ],
    viewBox: '0 0 320 512',
  },
  'info-circle': {
    path: [
      'M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z',
    ],
    viewBox: '0 0 512 512',
  },
  'cog-16': {
    path: [
      'M15.19,6.39h-1.85c-0.11-0.37-0.27-0.71-0.45-1.04l1.36-1.36c0.31-0.31,0.31-0.82,0-1.13l-1.13-1.13c-0.31-0.31-0.82-0.31-1.13,0l-1.36,1.36C10.3,2.92,9.96,2.76,9.59,2.65V0.79c0-0.44-0.36-0.8-0.8-0.8h-1.6c-0.44,0-0.8,0.36-0.8,0.8v1.86c-0.39,0.12-0.75,0.28-1.1,0.47l-1.3-1.3c-0.3-0.3-0.79-0.3-1.09,0L1.82,2.91c-0.3,0.3-0.3,0.79,0,1.09l1.3,1.3C2.92,5.64,2.76,6,2.64,6.39H0.79c-0.44,0-0.8,0.36-0.8,0.8v1.6c0,0.44,0.36,0.8,0.8,0.8h1.85c0.11,0.37,0.27,0.71,0.45,1.04l-1.36,1.36c-0.31,0.31-0.31,0.82,0,1.13l1.13,1.13c0.31,0.31,0.82,0.31,1.13,0l1.36-1.36c0.33,0.18,0.67,0.33,1.04,0.44v1.86c0,0.44,0.36,0.8,0.8,0.8h1.6c0.44,0,0.8-0.36,0.8-0.8v-1.86c0.39-0.12,0.75-0.28,1.1-0.47l1.3,1.3c0.3,0.3,0.79,0.3,1.09,0l1.09-1.09c0.3-0.3,0.3-0.79,0-1.09l-1.3-1.3c0.19-0.35,0.36-0.71,0.48-1.1h1.85c0.44,0,0.8-0.36,0.8-0.8v-1.6C15.99,6.75,15.63,6.39,15.19,6.39z M7.99,10.99c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3S9.65,10.99,7.99,10.99z',
    ],
    viewBox: '0 0 16 16',
  },
  'times-circle': {
    path: [
      'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z',
    ],
    viewBox: '0 0 512 512',
  },
  'file-alt': {
    path: [
      'M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16zm-48-244v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm0 64v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12z',
    ],
    viewBox: '0 0 384 512',
  },
  bigcapital: {
    path: [
      'M56,3.16,61.33,8.5,31.94,37.9l-5.35-5.35Z',
      'M29.53,6.94l5.35,5.34L5.49,41.67.14,36.33l15.8-15.8Z',
      'M94.36,38.87H79.62v-31H94c6.33,0,10.22,3.15,10.22,8V16a7.22,7.22,0,0,1-4.07,6.69c3.58,1.37,5.8,3.45,5.8,7.61v.09C106,36,101.35,38.87,94.36,38.87Zm3.1-21.81c0-2-1.59-3.19-4.47-3.19H86.26v6.55h6.29c3,0,4.91-1,4.91-3.28Zm1.72,12.39c0-2.08-1.54-3.37-5-3.37H86.26V32.9h8.1c3,0,4.82-1.06,4.82-3.36Z',
      'M110.56,12.54v-6h7.08v6Zm.18,26.33V15.15h6.72V38.87Z',
      'M134,46a22.55,22.55,0,0,1-10.49-2.47l2.3-5a15.52,15.52,0,0,0,8,2.17c4.61,0,6.78-2.21,6.78-6.46V33.08c-2,2.39-4.16,3.85-7.75,3.85-5.53,0-10.53-4-10.53-11.07v-.09c0-7.08,5.09-11.06,10.53-11.06a9.63,9.63,0,0,1,7.66,3.54v-3.1h6.72V33.52C147.2,42.46,142.78,46,134,46Zm6.6-20.27a5.79,5.79,0,0,0-11.56,0v.09a5.42,5.42,0,0,0,5.76,5.49,5.49,5.49,0,0,0,5.8-5.49Z',
      'M164,39.41a12.11,12.11,0,0,1-12.35-12.26v-.09a12.18,12.18,0,0,1,12.44-12.35c4.47,0,7.25,1.5,9.47,4l-4.12,4.43a6.93,6.93,0,0,0-5.4-2.61c-3.36,0-5.75,3-5.75,6.46v.09c0,3.63,2.34,6.55,6,6.55,2.26,0,3.8-1,5.44-2.53l3.94,4A12,12,0,0,1,164,39.41Z',
      'M191.51,38.87V36.31a9.15,9.15,0,0,1-7.17,3c-4.47,0-8.15-2.57-8.15-7.26V32c0-5.18,3.94-7.57,9.56-7.57a16.74,16.74,0,0,1,5.8,1V25c0-2.79-1.72-4.34-5.09-4.34a17.57,17.57,0,0,0-6.55,1.28l-1.68-5.13a21,21,0,0,1,9.21-1.9c7.34,0,10.57,3.8,10.57,10.22V38.87Zm.13-9.55a10.3,10.3,0,0,0-4.29-.89c-2.88,0-4.65,1.15-4.65,3.27v.09c0,1.82,1.5,2.88,3.67,2.88,3.15,0,5.27-1.73,5.27-4.16Z',
      'M217.49,39.32a9.1,9.1,0,0,1-7.39-3.54V46h-6.73V15.15h6.73v3.41a8.7,8.7,0,0,1,7.39-3.85c5.53,0,10.8,4.34,10.8,12.26v.09C228.29,35,223.11,39.32,217.49,39.32ZM221.56,27c0-3.94-2.66-6.55-5.8-6.55S210,23,210,27v.09c0,3.94,2.61,6.55,5.75,6.55s5.8-2.57,5.8-6.55Z',
      'M232.93,12.54v-6H240v6Zm.18,26.33V15.15h6.73V38.87Z',
      'M253.73,39.27c-4.11,0-6.9-1.63-6.9-7.12V20.91H244V15.15h2.83V9.09h6.73v6.06h5.57v5.76h-5.57V31c0,1.55.66,2.3,2.16,2.3A6.84,6.84,0,0,0,259,32.5v5.4A9.9,9.9,0,0,1,253.73,39.27Z',
      'M277.55,38.87V36.31a9.15,9.15,0,0,1-7.18,3c-4.46,0-8.14-2.57-8.14-7.26V32c0-5.18,3.94-7.57,9.56-7.57a16.74,16.74,0,0,1,5.8,1V25c0-2.79-1.73-4.34-5.09-4.34A17.57,17.57,0,0,0,266,21.92l-1.68-5.13a20.94,20.94,0,0,1,9.2-1.9c7.35,0,10.58,3.8,10.58,10.22V38.87Zm.13-9.55a10.31,10.31,0,0,0-4.3-.89c-2.87,0-4.64,1.15-4.64,3.27v.09c0,1.82,1.5,2.88,3.67,2.88,3.14,0,5.27-1.73,5.27-4.16Z',
      'M289.72,38.87V6.57h6.72v32.3Z',
      'M302.06,38.87V31.79h7.17v7.08Z',
    ],
    viewBox: '0 0 309.09 42.89',
  },
  'mini-bigcapital': {
    path: [
      'M57 3.16L62.33 8.5L32.94 37.9L27.59 32.55L57 3.16Z',
      'M30.53 6.94L35.88 12.28L6.49 41.67L1.14 36.33L16.94 20.53L30.53 6.94Z',
    ],
    viewBox: '0 0 63 43',
  },
  eye: {
    path: [
      'M288 144a110.94 110.94 0 0 0-31.24 5 55.4 55.4 0 0 1 7.24 27 56 56 0 0 1-56 56 55.4 55.4 0 0 1-27-7.24A111.71 111.71 0 1 0 288 144zm284.52 97.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400c-98.65 0-189.09-55-237.93-144C98.91 167 189.34 112 288 112s189.09 55 237.93 144C477.1 345 386.66 400 288 400z',
    ],
    viewBox: '0 0 576 512',
  },
  'eye-slash': {
    path: [
      'M634 471L36 3.51A16 16 0 0 0 13.51 6l-10 12.49A16 16 0 0 0 6 41l598 467.49a16 16 0 0 0 22.49-2.49l10-12.49A16 16 0 0 0 634 471zM296.79 146.47l134.79 105.38C429.36 191.91 380.48 144 320 144a112.26 112.26 0 0 0-23.21 2.47zm46.42 219.07L208.42 260.16C210.65 320.09 259.53 368 320 368a113 113 0 0 0 23.21-2.46zM320 112c98.65 0 189.09 55 237.93 144a285.53 285.53 0 0 1-44 60.2l37.74 29.5a333.7 333.7 0 0 0 52.9-75.11 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64c-36.7 0-71.71 7-104.63 18.81l46.41 36.29c18.94-4.3 38.34-7.1 58.22-7.1zm0 288c-98.65 0-189.08-55-237.93-144a285.47 285.47 0 0 1 44.05-60.19l-37.74-29.5a333.6 333.6 0 0 0-52.89 75.1 32.35 32.35 0 0 0 0 29.19C89.72 376.41 197.08 448 320 448c36.7 0 71.71-7.05 104.63-18.81l-46.41-36.28C359.28 397.2 339.89 400 320 400z',
    ],
    viewBox: '0 0 640 512',
  },
  'multi-select': {
    path: [
      'M12,3.98H4c-0.55,0-1,0.45-1,1v1h8v5h1c0.55,0,1-0.45,1-1v-5C13,4.43,12.55,3.98,12,3.98z M15,0.98H7c-0.55,0-1,0.45-1,1v1h8v5h1c0.55,0,1-0.45,1-1v-5C16,1.43,15.55,0.98,15,0.98z M9,6.98H1c-0.55,0-1,0.45-1,1v5c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v-5C10,7.43,9.55,6.98,9,6.98z M8,11.98H2v-3h6V11.98z',
    ],
    viewBox: '0 0 16 16',
  },
  'arrow-to-top': {
    path: [
      'M35.5 279.9l148-148.4c4.7-4.7 12.3-4.7 17 0l148 148.4c4.7 4.7 4.7 12.3 0 17l-19.6 19.6c-4.8 4.8-12.5 4.7-17.1-.2L218 219.2V468c0 6.6-5.4 12-12 12h-28c-6.6 0-12-5.4-12-12V219.2l-93.7 97.1c-4.7 4.8-12.4 4.9-17.1.2l-19.6-19.6c-4.8-4.7-4.8-12.3-.1-17zM12 84h360c6.6 0 12-5.4 12-12V44c0-6.6-5.4-12-12-12H12C5.4 32 0 37.4 0 44v28c0 6.6 5.4 12 12 12z',
    ],
    viewBox: '0 0 384 512',
  },
  'arrow-to-bottom': {
    path: [
      'M348.5 232.1l-148 148.4c-4.7 4.7-12.3 4.7-17 0l-148-148.4c-4.7-4.7-4.7-12.3 0-17l19.6-19.6c4.8-4.8 12.5-4.7 17.1.2l93.7 97.1V44c0-6.6 5.4-12 12-12h28c6.6 0 12 5.4 12 12v248.8l93.7-97.1c4.7-4.8 12.4-4.9 17.1-.2l19.6 19.6c4.9 4.7 4.9 12.3.2 17zM372 428H12c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12v-28c0-6.6-5.4-12-12-12z',
    ],
    viewBox: '0 0 384 512',
  },
  'search-24': {
    path: [
      'M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z',
    ],
    viewBox: '0 0 24 24',
  },
  'plus-24': {
    path: ['M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'],
    viewBox: '0 0 24 24',
  },
  'notification-24': {
    path: [
      'M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z',
    ],
    viewBox: '0 0 24 24',
  },
  'help-24': {
    path: [
      'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-4h2v2h-2zm1.61-9.96c-2.06-.3-3.88.97-4.43 2.79-.18.58.26 1.17.87 1.17h.2c.41 0 .74-.29.88-.67.32-.89 1.27-1.5 2.3-1.28.95.2 1.65 1.13 1.57 2.1-.1 1.34-1.62 1.63-2.45 2.88 0 .01-.01.01-.01.02-.01.02-.02.03-.03.05-.09.15-.18.32-.25.5-.01.03-.03.05-.04.08-.01.02-.01.04-.02.07-.12.34-.2.75-.2 1.25h2c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z',
    ],
    viewBox: '0 0 24 24',
  },

  // 16
  'file-import-16': {
    path: [
      'M6,10.41l-3.29,3.3A1.0041,1.0041,0,0,1,1,13a.9655.9655,0,0,1,.3-.71L4.59,9H3A1,1,0,0,1,3,7H7A1.0029,1.0029,0,0,1,8,8v4a1,1,0,0,1-2,0ZM9.8818,0,5.9947-.0147A2.9947,2.9947,0,0,0,3,2.98V4.9929H5V1.9975H9V5.99h4V14H5.0183L4.011,15.0072l-.1248.1249a2.955,2.955,0,0,0,2.1085.8825h6.0106A2.9946,2.9946,0,0,0,15,13.02V5.1182L12.4409,2.5591Z',
    ],
    viewBox: '0 0 16 16',
  },
  'file-export-16': {
    path: [
      'M3,9.5852l3.29-3.294A1.0045,1.0045,0,0,1,8,7a.9627.9627,0,0,1-.3.7087l-3.29,3.284H6A.9982.9982,0,1,1,6,12.989H2a1.002,1.002,0,0,1-1-.9982V7.9981a1,1,0,0,1,2,0ZM9.8818.0146,5.9947,0A2.992,2.992,0,0,0,3,2.9892V4.9984H5v-2.99H9V5.9937h4V13.989H5.0183L4.011,14.9944l-.1248.1246A2.9574,2.9574,0,0,0,5.9947,16h6.0106A2.992,2.992,0,0,0,15,13.0108V5.1234L12.4409,2.569Z',
    ],
    viewBox: '0 0 16 16',
  },
  'trash-16': {
    path: [
      'M2.9,14.2149A1.7915,1.7915,0,0,0,4.6,16h6.8a1.7915,1.7915,0,0,0,1.7-1.7851V4H2.9Z',
      'M14,1.0645H11L10.1,0H5.9L5,1.0645H2V3H14Z',
    ],
    viewBox: '0 0 16 16',
  },
  'pause-16': {
    path: ['M3,13H6V3H3ZM10,3V13h3V3Z'],
    viewBox: '0 0 16 16',
  },
  'filter-16': {
    path: ['M6,13h4V11H6ZM0,3V5H16V3ZM3,9H13V7H3Z'],
    viewBox: '0 0 16 16',
  },
  'print-16': {
    path: [
      'M12,1H4V4h8Zm1,3.9948H3a3,3,0,0,0-3,3v3H2.9561V15H13V10.9948h3v-3A3,3,0,0,0,13,4.9948ZM11,13H5V9h6Zm3.8869-5.5446A.7109.7109,0,1,1,13.54,7l-.04-.02c.02-.0121.04-.0238.061-.0345a.7109.7109,0,0,1,1.3259.51Z',
    ],
    viewBox: '0 0 16 16',
  },
  'play-16': {
    path: [
      'M12,8c0-0.35-0.19-0.64-0.46-0.82l0.01-0.02l-6-4L5.54,3.18C5.39,3.08,5.21,3,5,3C4.45,3,4,3.45,4,4v8c0,0.55,0.45,1,1,1c0.21,0,0.39-0.08,0.54-0.18l0.01,0.02l6-4l-0.01-0.02C11.81,8.64,12,8.35,12,8z',
    ],
    viewBox: '0 0 16 16',
  },
  'more-h-16': {
    path: [
      'M2.5,6A1.5,1.5,0,1,0,4,7.5,1.5044,1.5044,0,0,0,2.5,6Zm11,0A1.5,1.5,0,1,0,15,7.5,1.5044,1.5044,0,0,0,13.5,6ZM8,6A1.5,1.5,0,1,0,9.5,7.5,1.5044,1.5044,0,0,0,8,6Z',
    ],
    viewBox: '0 0 16 16',
  },
  'table-16': {
    path: [
      'M0 1.994C0 .893.895 0 1.994 0h12.012C15.107 0 16 .895 16 1.994v12.012A1.995 1.995 0 0 1 14.006 16H1.994A1.995 1.995 0 0 1 0 14.006V1.994zM2 7v1.002C2 8.557 2.452 9 3.01 9h1.98A.999.999 0 0 0 6 8.001V6.999C6 6.443 5.548 6 4.99 6H3.01A.999.999 0 0 0 2 6.999zm5 0v1.002C7 8.557 7.449 9 8.003 9h4.994A.994.994 0 0 0 14 8.001V6.999A.998.998 0 0 0 12.997 6H8.003A.994.994 0 0 0 7 6.999zM2 3c0 .556.452 1 1.01 1h1.98a1 1 0 1 0 0-2H3.01A1 1 0 0 0 2 3zm5 0c0 .556.449 1 1.003 1h4.994A.994.994 0 0 0 14 3c0-.556-.449-1-1.003-1H8.003A.994.994 0 0 0 7 3zm-5 8.999v1.002c0 .556.452.999 1.01.999h1.98A.999.999 0 0 0 6 13.001v-1.002C6 11.443 5.548 11 4.99 11H3.01a.999.999 0 0 0-1.01.999zm5 0v1.002c0 .556.449.999 1.003.999h4.994A.994.994 0 0 0 14 13.001v-1.002A.998.998 0 0 0 12.997 11H8.003A.994.994 0 0 0 7 11.999z',
    ],
    viewBox: '0 0 16 16',
  },
  'refresh-16': {
    path: [
      'M13.645,2.35A8.0014,8.0014,0,1,0,15.725,10h-2.08a5.9972,5.9972,0,1,1-5.65-8,5.9149,5.9149,0,0,1,4.22,1.78L8.995,7h7V0Z',
    ],
    viewBox: '0 0 16 16',
  },
  'settings-18': {
    path: [
      'M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z',
    ],
    viewBox: '0 0 24 24',
  },
  'pen-18': {
    path: [
      'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
    ],
    viewBox: '0 0 24 24',
  },
  'reader-18': {
    path: [
      'M13 12h7v1.5h-7zm0-2.5h7V11h-7zm0 5h7V16h-7zM21 4H3c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15h-9V6h9v13z',
    ],
    viewBox: '0 0 24 24',
  },
  'library-add-18': {
    path: [
      'M16 9h-2.55V6h-2.9v3H8l4 4zm3-6H4.99C3.88 3 3 3.9 3 5v14c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5v-3h3.56c.69 1.19 1.97 2 3.45 2s2.75-.81 3.45-2H19v3zm0-5h-4.99c0 1.1-.9 2-2 2s-2-.9-2-2H5l-.01-9H19v9z',
    ],
    viewBox: '0 0 24 24',
  },
  'arrow-drop-up-16': {
    path: ['M7 14l5-5 5 5z'],
    viewBox: '0 0 24 24',
  },
  'checkmark-16': {
    path: ['M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'],
    viewBox: '0 0 24 24',
  },
  'date-range': {
    path: [
      'M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z',
    ],
    viewBox: '0 0 24 24',
  },
  hash: {
    path: [
      'M14.1683,13.45l.976-3.7971H10.0652L9.1144,13.45Zm2.0976,0H20.57l-.7427,2.1451H15.7281l-1.31,5.2292-2.1511.0208,1.3489-5.25h-5.04l-1.31,5.2292-2.1511.0208,1.35-5.25H2L2.7429,13.45H7.0154l.976-3.7971H3.4307l.7428-2.1451H8.544l1.15-4.4716L11.732,3,10.603,7.5074h5.0954l1.1454-4.4567L18.8864,3l-1.129,4.5072H22l-.7428,2.1451H17.22L16.2689,13.45Z',
    ],
    viewBox: '0 0 24 24',
  },
  bookmark: {
    path: ['M19 5H5v16l7-5 7 5V4z'],
    viewBox: '0 0 24 24',
  },
  person: {
    path: [
      'M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z',
    ],
    viewBox: '0 0 24 24',
  },
  info: {
    path: [
      'M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z',
    ],
    viewBox: '0 0 24 24',
  },
  'info-block': {
    path: [
      'M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM7 3h2v2H7V3zm3 10H6v-1h1V7H6V6h3v6h1v1z',
    ],
    viewBox: '0 0 16 16',
  },
  currency: {
    path: [
      'M10.9571,13.44a6.9356,6.9356,0,0,0,0-9.2751,4.8008,4.8008,0,1,1,0,9.2751Zm-5.156.1632a4.8008,4.8008,0,1,1,4.8008-4.8008A4.8008,4.8008,0,0,1,5.8011,13.603Z',
    ],
    viewBox: '0 0 18 18',
  },
  'short-text-16': {
    path: ['M13.6668,7H2V5H13.6668Zm-5.5862,2h-6v2h6Z'],
    viewBox: '0 0 16 16',
  },
  'tag-16': {
    path: ['M14,4v9H5L2,8.5,5,4Z'],
    viewBox: '0 0 16 16',
  },
  payments: {
    path: [
      'M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-9-1c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-6v11c0 1.1-.9 2-2 2H4v-2h17V7h2z',
    ],
    viewBox: '0 0 24 24',
  },
  'arrow-left': {
    path: ['M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z'],
    viewBox: '0 0 24 24',
  },
  numbers: {
    path: [
      'M2.9377,11V5.26q0-.3633.0069-.9238T2.98,3.3a3.6334,3.6334,0,0,1-.35.3785q-.1677.1538-.3642.3076L1.16,4.9105.0535,3.5246,3.19,1.0046H5.08V11Z',
      'M7.8655,11V9.5158l2.5058-2.5332q.7559-.77,1.2178-1.295a4.512,4.512,0,0,0,.6719-.9521,2.0537,2.0537,0,0,0,.21-.917,1.0375,1.0375,0,0,0-.3711-.875,1.455,1.455,0,0,0-.917-.2871,2.2924,2.2924,0,0,0-1.0712.2661A6.6756,6.6756,0,0,0,9,3.6789L7.8376,2.2926A10.771,10.771,0,0,1,8.7615,1.6a4.4132,4.4132,0,0,1,1.1264-.5323A4.931,4.931,0,0,1,11.3791.8644a3.9291,3.9291,0,0,1,1.7012.3433,2.6249,2.6249,0,0,1,1.1123.9521,2.5386,2.5386,0,0,1,.3926,1.4068A3.5845,3.5845,0,0,1,14.27,5.0788a5.32,5.32,0,0,1-.9307,1.3579q-.6166.6715-1.4981,1.498L10.595,9.1378v.07h4.27V11Z',
      'M18.91,11.14a7.8841,7.8841,0,0,1-1.582-.14,7.3067,7.3067,0,0,1-1.3155-.4062v-1.82a5.5853,5.5853,0,0,0,1.3438.498,5.9318,5.9318,0,0,0,1.3164.16,2.5022,2.5022,0,0,0,1.5742-.3779,1.2582,1.2582,0,0,0,.4556-1.0216,1.105,1.105,0,0,0-.5459-1.0078,3.7194,3.7194,0,0,0-1.8477-.336h-.7422V5.0368h.7558a3.0031,3.0031,0,0,0,1.708-.378,1.167,1.167,0,0,0,.5322-.9941.93.93,0,0,0-.3779-.8052,1.7126,1.7126,0,0,0-1.0225-.2729,3.1339,3.1339,0,0,0-1.28.2451,6.4963,6.4963,0,0,0-.917.4829l-.9248-1.4277a6.0463,6.0463,0,0,1,1.3867-.7212A5.67,5.67,0,0,1,19.4149.8644a3.838,3.838,0,0,1,2.3945.6514,2.08,2.08,0,0,1,.84,1.729,2.2193,2.2193,0,0,1-.6231,1.6518,3.2277,3.2277,0,0,1-1.5332.84v.042a3.1845,3.1845,0,0,1,1.8272.7422,2.1271,2.1271,0,0,1,.623,1.624,2.8,2.8,0,0,1-.4267,1.5185A2.9013,2.9013,0,0,1,21.2,10.7415,5.6336,5.6336,0,0,1,18.91,11.14Z',
    ],
    viewBox: '0 0 23 12',
  },
  'small-tick': {
    path: [
      'M10 20C4.48 20 0 15.52 0 10S4.48 0 10 0s10 4.48 10 10-4.48 10-10 10zm5-14c-.28 0-.53.11-.71.29L8 12.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29.28 0 .53-.11.71-.29l7-7A1.003 1.003 0 0015 6z',
    ],
    viewBox: '0 0 20 20',
  },
  'swap-vert': {
    path: [
      'M10.6,10.9V5.4H9v5.5H6.7L9.8,14l3.1-3.1ZM5.1,0,2,3.1H4.3V8.6H5.9V3.1H8.2Z',
    ],
    viewBox: '0 0 14 14',
  },
  check: {
    path: [
      'M17 4c-.28 0-.53.11-.71.29L7 13.59 3.71 10.3A.965.965 0 003 10a1.003 1.003 0 00-.71 1.71l4 4c.18.18.43.29.71.29s.53-.11.71-.29l10-10A1.003 1.003 0 0017 4z',
    ],
    viewBox: '0 0 24 24',
  },
  'close-black': {
    path: [
      'M11.41 10l4.29-4.29c.19-.18.3-.43.3-.71a1.003 1.003 0 00-1.71-.71L10 8.59l-4.29-4.3a1.003 1.003 0 00-1.42 1.42L8.59 10 4.3 14.29c-.19.18-.3.43-.3.71a1.003 1.003 0 001.71.71l4.29-4.3 4.29 4.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71L11.41 10z',
    ],
    viewBox: '0 0 20 20',
  },
  send: {
    path: ['M2.01 21L23 12 2.01 3 2 10l15 2-15 2z'],
    viewBox: '0 0 24 24',
  },
  'arrow-top-right': {
    path: ['M9,5v2h6.59L4,18.59L5.41,20L17,8.41V15h2V5H9'],
    viewBox: '0 0 24 24',
  },
  'receipt-24': {
    path: [
      'M19.5 3.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5zM19 19.09H5V4.91h14v14.18zM6 15h12v2H6zm0-4h12v2H6zm0-4h12v2H6z',
    ],
    viewBox: '0 0 24 24',
  },
  convert_to: {
    path: [
      'M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46C8.97 19.54 10.43 20 12 20c4.42 0 8-3.58 8-8h3l-4-4zM6 12c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46C15.03 4.46 13.57 4 12 4c-4.42 0-8 3.58-8 8H1l4 4 4-4H6z',
    ],
    viewBox: '0 0 24 24',
  },
  'duplicate-24': {
    path: [
      'M14.15 16.05Q14.525 16.45 15.075 16.45Q15.625 16.45 16.05 16.025Q16.425 15.65 16.425 15.088Q16.425 14.525 16.05 14.125L13.375 11.425V8.275Q13.375 7.725 12.988 7.337Q12.6 6.95 12.025 6.95Q11.45 6.95 11.05 7.35Q10.65 7.75 10.65 8.3V11.975Q10.65 12.275 10.75 12.512Q10.85 12.75 11.075 12.975ZM12 22.875Q9.75 22.875 7.763 22.025Q5.775 21.175 4.3 19.7Q2.825 18.225 1.975 16.238Q1.125 14.25 1.125 12Q1.125 9.725 1.975 7.737Q2.825 5.75 4.3 4.275Q5.775 2.8 7.763 1.962Q9.75 1.125 12 1.125Q14.275 1.125 16.262 1.962Q18.25 2.8 19.725 4.275Q21.2 5.75 22.038 7.737Q22.875 9.725 22.875 12Q22.875 14.275 22.038 16.25Q21.2 18.225 19.725 19.7Q18.25 21.175 16.262 22.025Q14.275 22.875 12 22.875ZM12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12Q12 12 12 12ZM12 20.05Q15.35 20.05 17.7 17.712Q20.05 15.375 20.05 12Q20.05 8.625 17.7 6.287Q15.35 3.95 12 3.95Q8.65 3.95 6.3 6.287Q3.95 8.625 3.95 12Q3.95 15.375 6.3 17.712Q8.65 20.05 12 20.05Z',
    ],
    viewBox: '0 0 24 24',
  },
  'caret-down-16': {
    path: [
      'M12 6.5c0-.28-.22-.5-.5-.5h-7a.495.495 0 00-.37.83l3.5 4c.09.1.22.17.37.17s.28-.07.37-.17l3.5-4c.08-.09.13-.2.13-.33z',
    ],
    viewBox: '0 0 16 16',
  },
  'quick-payment-16': {
    path: [
      'M19,14V6c0-1.1-0.9-2-2-2H3C1.9,4,1,4.9,1,6v8c0,1.1,0.9,2,2,2h14C18.1,16,19,15.1,19,14z M17,14H3V6h14V14z M10,7 c-1.66,0-3,1.34-3,3s1.34,3,3,3s3-1.34,3-3S11.66,7,10,7z M23,7v11c0,1.1-0.9,2-2,2H4c0-1,0-0.9,0-2h17V7C22.1,7,22,7,23,7z',
    ],
    viewBox: '0 0 24 24',
  },

  'trash-18': {
    path: [
      'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z',
    ],
    viewBox: '0 0 24 24',
  },
  'universal-search': {
    path: [
      'M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z',
    ],
    viewBox: '0 0 20 20',
  },
  'arrow-down-24': {
    path: ['M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z'],
    viewBox: '0 0 24 24',
  },
  'arrow-up-24': {
    path: ['M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z'],
    viewBox: '0 0 24 24',
  },
  'caret-right-16': {
    path: [
      'M11 8c0-.15-.07-.28-.17-.37l-4-3.5A.495.495 0 006 4.5v7a.495.495 0 00.83.37l4-3.5c.1-.09.17-.22.17-.37z',
    ],
    viewBox: '0 0 16 16',
  },
  'table-row-small': {
    path: [
      'M15,2.5v2a.5.5,0,0,1-.5.5h-13a.5.5,0,0,1-.5-.5v-2A.51.51,0,0,1,1.49,2h13A.5.5,0,0,1,15,2.5Z',
      'M1,7.51A.51.51,0,0,1,1.49,7h13a.51.51,0,0,1,0,1h-13A.51.51,0,0,1,1,7.51Z',
      'M1,10.5a.5.5,0,0,1,.5-.5h13a.5.5,0,0,1,0,1h-13A.5.5,0,0,1,1,10.5Z',
      'M15,13.5a.5.5,0,0,1-.5.51h-13a.51.51,0,0,1,0-1h13A.5.5,0,0,1,15,13.5Z',
    ],
    viewBox: '0 0 16 16',
  },
  'table-row-medium': {
    path: [
      'M15,3V7a1,1,0,0,1-1,1H2A1,1,0,0,1,1,7V3A1,1,0,0,1,2,2H14A1,1,0,0,1,15,3ZM1.48,11h13a.5.5,0,0,0,0-1h-13a.5.5,0,0,0,0,1Zm13,2h-13a.51.51,0,0,0,0,1h13a.51.51,0,0,0,0-1Z',
    ],
    viewBox: '0 0 16 16',
  },
  'account-balance': {
    path: [
      'M6.5 10h-2v7h2v-7zm6 0h-2v7h2v-7zm8.5 9H2v2h19v-2zm-2.5-9h-2v7h2v-7zm-7-6.74L16.71 6H6.29l5.21-2.74m0-2.26L2 6v2h19V6l-9.5-5z',
    ],
    viewBox: '0 0 24 24',
  },
  'credit-card': {
    path: [
      'M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z',
    ],
    viewBox: '0 0 24 24',
  },
  'arrow-drop-down': {
    path: ['M7 10l5 5 5-5H7z'],
    viewBox: '0 0 24 24',
  },
  'arrow-downward': {
    path: ['M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z'],
    viewBox: '0 0 24 24',
  },
  'arrow-upward': {
    path: ['M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z'],
    viewBox: '0 0 24 24',
  },
  'more-vert': {
    path: [
      'M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z',
    ],
    viewBox: '0 0 24 24',
  },
  'sms-message-preview': {
    path: [
      'M8.341,375.3573H399.3271v-.0015l-390.9861-.07ZM363.2382,0H44.43A44.4508,44.4508,0,0,0,0,44.371V375.284l8.341.0016V44.371A36.0651,36.0651,0,0,1,44.43,8.33H90.7089a4.6454,4.6454,0,0,1,4.6482,4.6423v1.9718a23.8588,23.8588,0,0,0,23.8742,23.843H288.9146a23.8586,23.8586,0,0,0,23.8741-23.843V12.972A4.6456,4.6456,0,0,1,317.4372,8.33h45.801A36.0651,36.0651,0,0,1,399.3271,44.371V375.3558l8.341.0015V44.371A44.4508,44.4508,0,0,0,363.2382,0Z',
      'M1199.9485,803.1623',
    ],
    viewBox: '0 0 407.6681 375.3573',
  },
  lock: {
    path: [
      'M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z',
    ],
    viewBox: '0 0 24 24',
  },
  'warehouse-16': {
    path: [
      'M20,8.35V19h-2v-8H6v8H4V8.35l8-3.2L20,8.35z M22,21V7L12,3L2,7v14h6v-8h8v8H22z M11,19H9v2h2V19z M13,16h-2v2h2V16z M15,19h-2v2h2V19z',
    ],
    viewBox: '0 0 24 24',
  },
  'branch-16': {
    path: [
      'M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z',
    ],
    viewBox: '0 0 24 24',
  },
  'case-16': {
    path: [
      'M8 6V4q0-.825.588-1.413Q9.175 2 10 2h4q.825 0 1.413.587Q16 3.175 16 4v2h4q.825 0 1.413.588Q22 7.175 22 8v11q0 .825-.587 1.413Q20.825 21 20 21H4q-.825 0-1.412-.587Q2 19.825 2 19V8q0-.825.588-1.412Q3.175 6 4 6Zm2 0h4V4h-4Zm10 9h-5v2H9v-2H4v4h16Zm-9 0h2v-2h-2Zm-7-2h5v-2h6v2h5V8H4Zm8 1Z',
    ],
    viewBox: '0 0 24 24',
  },
  'more-13': {
    path: [
      'M1.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 10a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0-5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z',
    ],
    viewBox: '0 0 3 13',
  },
  'time-24': {
    path: [
      'M0 7c0-3.873 3.127-7 7-7s7 3.127 7 7-3.127 7-7 7a6.99 6.99 0 0 1-7-7zm1.5 0c0 3.043 2.457 5.5 5.5 5.5s5.5-2.457 5.5-5.5S10.043 1.5 7 1.5A5.493 5.493 0 0 0 1.5 7zM6 4h1v3h3v1H6V4z',
    ],
    viewBox: '0 0 14 14',
  },
  'star-18dp': {
    path: [
      'M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z',
    ],
    viewBox: '0 0 24 24',
  },
  'content-copy': {
    path: [
      'M15 0H5c-.55 0-1 .45-1 1v2h2V2h8v7h-1v2h2c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-4 4H1c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1zm-1 10H2V6h8v8z',
    ],
    viewBox: '0 0 16 16',
  },
  envelope: {
    path: [
      'M0 4.01v11.91l6.27-6.27L0 4.01zm18.91-1.03H1.09L10 10.97l8.91-7.99zm-5.18 6.66L20 15.92V4.01l-6.27 5.63zm-3.23 2.9c-.13.12-.31.19-.5.19s-.37-.07-.5-.19l-2.11-1.89-6.33 6.33h17.88l-6.33-6.33-2.11 1.89z',
    ],
    viewBox: '0 0 20 20',
  },
};
